import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadataSizes = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            sizes {
              desktop
              laptopL
              laptop
              tablet
              mobileL
              mobile
              mobileS
            }
          }
        }
      }
    `,
  );
  return site.siteMetadata.sizes;
};

export { useSiteMetadataSizes };
