import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, css } from 'styled-components';
// import { useStaticQuery, graphql, Link } from 'gatsby';
// import { useSpring, useTransition, animated } from 'react-spring';

import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';

require('typeface-rubik');
require('typeface-lato');
require('typeface-karla');
require('typeface-space-mono');
require('typeface-pt-serif');
require('typeface-arvo');
require('typeface-open-sans');

// todo: remove global style. i don't have access to the overall entry point.
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f5f5; //#777; //; //black;
    font-family: 'Rubik', 'Karla', 'Helvetica Neue';
    font-weight: 300;
    font-size: 10pt;
    margin: 0;
    padding: 0;
    color: #222;

    @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
    @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
    @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
    @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
    @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
  }

  h1 {
    font-family: 'Pt Serif';
    font-size: 34pt;
    font-weight: 300;
    line-height: 1.35em;
    @media (max-width: ${({ sizes }) => sizes.desktop}px) { font-size: 32pt; }
    @media (max-width: ${({ sizes }) => sizes.laptopL}px) { font-size: 28pt; }
    @media (max-width: ${({ sizes }) => sizes.laptop}px)  { font-size: 24pt; }
    @media (max-width: ${({ sizes }) => sizes.tablet}px)  { font-size: 20pt; }
    @media (max-width: ${({ sizes }) => sizes.mobileL}px) { font-size: 18pt; }
    @media (max-width: ${({ sizes }) => sizes.mobile}px)  { font-size: 16pt; }
    @media (max-width: ${({ sizes }) => sizes.mobileS}px) { font-size: 14pt; }
  }

  h2 {
    font-family: 'Pt Serif';
    font-size: 26px;
    font-weight: 300;
    line-height: 1.35em;
    @media (max-width: ${({ sizes }) => sizes.desktop}px) {font-size: 24px; }
    @media (max-width: ${({ sizes }) => sizes.laptopL}px) { font-size: 22px;}
    @media (max-width: ${({ sizes }) => sizes.laptop}px)  { font-size: 20px; }
    @media (max-width: ${({ sizes }) => sizes.tablet}px)  { font-size: 30px; margin-bottom: 0; }
    @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
    @media (max-width: ${({ sizes }) => sizes.mobile}px)  { font-size: 26px;}
    @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
  }

  h3 {
    font-family: 'Rubik', 'Open Sans';
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 1.35em;
    @media (max-width: ${({ sizes }) => sizes.desktop}px) {font-size: 18px; }
    @media (max-width: ${({ sizes }) => sizes.laptopL}px) { font-size: 18px;}
    @media (max-width: ${({ sizes }) => sizes.laptop}px)  { font-size: 18px; }
    @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
    @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
  }
  
  h4 {
    font-family: 'Rubik', 'Open Sans';
    font-size: 10pt;
    font-style: italic;
    font-weight: 500;
    margin: 0.5rem 0 0.25rem 0;
  }

  a {
    color: #000;
    font-weight: 300;
    text-decoration: none;

    ${'' /* &:hover {
      font-weight: 500;
      transition: 0.5s ease-out;
    } */}
  }

  li {
    margin-left: 1.0rem;
    list-style-type: none;
    color: #777;
    font-size: 10pt;
    font-weight: 400;
    margin-top: 0.3rem;
  }

  button {
    font-size: 16px;
    padding: 0;
    margin: 0;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
    @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
    @media (max-width: ${({ sizes }) => sizes.laptop}px)  { font-size: 14px; }
    @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileL}px) { font-size: 12px; }
    @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
    @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
  }
`;

const Layout = ({ children }) => {
  const sizes = useSiteMetadataSizes();

  return (
    <>
      <GlobalStyle sizes={sizes} />
      {/* TODO: come up with a better thing to go inbetween the header
       and the tags instead of flipping it with the project grid */}
      { children }
      {/* <footer>
        {`© All work copyright Bryan Ma and respective collaborators ${new Date().getFullYear()}`}
      </footer> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
